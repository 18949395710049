<template>
  <div v-if="reviews.length" class="reviews-block layout-item">
    <div class="layout-item--title">Отзывы</div>
    <div class="review" v-for="review of firstReviews" :key="review.key">
      <div class="review-head">
        <span class="review-name">{{ review.title }}</span>
        <span class="review-date">{{ review.date }}</span>
      </div>
      <div class="review-text">{{ review.description }}</div>
    </div>
    <button v-if="allReviews.length" @click="showAll = true" class="btn btn-o">
      Показать ещё {{ allReviews.length }}
    </button>
    <transition name="slide-down">
      <div v-if="showAll">
        <div class="review" v-for="review of allReviews" :key="review.key">
          <div class="review-head">
            <span class="review-name">{{ review.title }}</span>
            <span class="review-date">{{ review.date }}</span>
          </div>
          <div class="review-text">{{ review.description }}</div>
        </div>
        <button @click="showAll = false" class="btn btn-o">Скрыть</button>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref, toRef } from "vue";

export default {
  name: "reviews-block",
  props: {
    reviews: Array,
  },
  setup(props) {
    const showAll = ref(false);
    const reviews = toRef(props, "reviews");
    const firstReviews = computed(() => reviews.value.slice(0, 3));
    const allReviews = computed(() => reviews.value.slice(3));

    return {
      showAll,
      firstReviews,
      allReviews,
    };
  },
};
</script>

<style lang="scss" scoped>
.reviews-block {
  margin-top: 44px;
}
.review {
  padding: 15px 0;
  border-top: 1px solid var(--border-color);

  .review-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .review-name {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 12px;
    word-break: break-word;
    padding-right: 20px;
  }

  .review-date {
    color: #959abb;
    font-size: 12px;
    font-weight: 400;
    min-width: 91px;
  }

  .review-text {
    font-size: 18px;
  }
}
</style>